import api from './api'

interface WaitlistResponse {
	message: string
}

export const addToWaitlist = async (email: string): Promise<WaitlistResponse> => {
	try {
		const response = await api.post<WaitlistResponse>('/waitlist', { email })
		return response.data
	} catch (error: any) {
		console.error('Erro ao adicionar e-mail à lista de espera:', error)
		throw new Error(error?.response?.data?.message || 'Erro ao cadastrar e-mail')
	}
}
