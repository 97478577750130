import axios from 'axios'
import type { NextAuthConfig } from 'next-auth'
import CredentialsProvider from 'next-auth/providers/credentials'
import FacebookProvider from 'next-auth/providers/facebook'
import GoogleProvider from 'next-auth/providers/google'

const apiUrl = process.env.NEXT_PUBLIC_API_URL

export default {
	providers: [
		GoogleProvider({
			clientId: process.env.AUTH_GOOGLE_ID!,
			clientSecret: process.env.AUTH_GOOGLE_SECRET!,
		}),
		FacebookProvider({
			clientId: process.env.AUTH_FACEBOOK_ID!,
			clientSecret: process.env.AUTH_FACEBOOK_SECRET!,
		}),
		CredentialsProvider({
			name: 'Credentials',
			credentials: {
				usernameOrEmail: { label: 'Username or Email', type: 'text' },
				password: { label: 'Password', type: 'password' },
			},
			async authorize(credentials) {
				try {
					const res = await axios.post(`${apiUrl}/auth/login`, {
						usernameOrEmail: credentials?.usernameOrEmail,
						password: credentials?.password,
					})

					const { token, refreshToken, user } = res.data

					if (user && token) {
						return { ...user, accessToken: token, refreshToken, image: user.user_avatar }
					}

					return null
				} catch (error: any) {
					console.error('Error during authentication:', error.response?.data || error.message)
					return null
				}
			},
		}),
	],
} satisfies NextAuthConfig
